import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/SEO";
import Youtube from "react-lazyload-youtube";

function OvelserMedExertube() {
  return (
    <>
      <SEO
        title="Hvilke øvelser kan laves med exertube og hvilke er de bedste exertube?"
        description="Exertube er populære som aldrig før. Og det er der en rigtig god grund til. Læs her hvorfor og hvilke øvelser du kan foretage med dem."
      />
      <article className="flex flex-col items-center mx-auto max-w-4xl my-12">
        <h1 className="font-bold text-4xl mb-4">
          Derfor er exertube populære som aldrig før
        </h1>
        <p className="mb-4 text-2xl leading-10">
          Corona kom til landet. Pludselig var alt lukket. Heriblandt fitness
          centrene. Danskerne begyndte at træne hjemme som aldrig før og her kom
          exertube virkelig frem. <br />
          De fylder næsten ingenting. De kan lægges ind bagerst i skabet eller
          under sofaen på ingen tid. Og ligeså hurtigt er det, at finde dem frem
          igen. <br />
          Udover det, kan de benyttes af stortset alle, da de er elastikker og
          nemt at tilpasse modstanden/styrken.
        </p>
        <h2 className="font-bold text-4xl mb-4">
          Hvilke øvelser kan jeg lave med exertube?
        </h2>
        <p className="mb-4 text-2xl leading-10">
          Endnu en af de ting, der gør exertube særligt populære, er at du kan
          få trænet alle muskler og led. De er ikke særligt tilegnet den ene
          eller anden muskel - men nem at ramme alle, med forskellige øvelser.
          Vi har her samlet nogle gode youtube videoer, hvor du kan se, hvordan
          du kan bruge exertube.
        </p>
        <h2 className="font-bold text-3xl mb-4">Rygtræning med exertube</h2>
        <Youtube height="400px" videoId="IP4wM2JpDdQ" />
        <h2 className="font-bold text-3xl my-4">Overkrop med exertube</h2>
        <Youtube height="400px" videoId="oBsUhsWcAcc" />
        <h2 className="font-bold text-3xl my-4">
          11 øvelser fra Billig Fitness
        </h2>
        <Youtube height="400px" videoId="1IburbeeQRg" />
        <div className="my-4">
          <p>
            Med disse øvelser, kan man komme rigtig langt og er godt dækket med
            hele kroppen.
          </p>
          Du kan læse mere om den Exertube pakke vi anbefaler{' '}
          <Link to="/udstyr/odin-exertube-træningselastik-pakke" className="font-bold">her</Link>
        </div>
      </article>
    </>
  );
}

export default OvelserMedExertube;
